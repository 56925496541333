.footer-block {
    background-color: black;
    display: flex;
    padding: 7vh;
    align-items: flex-start;
    height: auto;
    border-top: 2px solid white;
}

.footer {
    /* height: 10vh; */
    display: flex;
    flex-direction: row;
    margin: auto;
}

.footer-link {
    color: white;
    text-underline-offset: 5px;
}

.divider {
    margin-right: 5vw;
    margin-left: 5vw;
}

.socials-content {
    margin: auto;
    /* margin-left: 5vw; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 0.5vh;
    padding-bottom: 0.5vh;
    /* margin-bottom: -3vh; */
}

.socials-icon {
    object-fit:scale-down;
    /* height: 120%; */
    filter: invert(100%) sepia(38%) saturate(0%) hue-rotate(163deg) brightness(111%) contrast(100%);
}

.socials-icon {
    width: 35px;
    height: 35px;
    margin: 5px;
}

@media screen and (max-aspect-ratio: 6/5)
{
    .divider, .unecessary-footer-text {
        display: none;
    }
    .footer {
        flex-direction: column;
    }
    .footer-text {
        text-align: center;
    }
}