.block {
  background-color: black;
  border-bottom: 1px solid #000;
  overflow: hidden;
  /* height: auto; */
  /* height: 100vh; */
}

.highlighted:hover {
  color: gray;
  filter: invert(48%) sepia(6%) saturate(6%) hue-rotate(329deg) brightness(102%) contrast(89%);
  transform: scale(1.1);
}

.highlighted-text:hover {
  filter: invert(48%) sepia(6%) saturate(6%) hue-rotate(329deg) brightness(102%) contrast(89%);
  text-decoration: underline;
  /* text-underline-offset: 5px; */
}


@media screen and (max-width: 800px)
{
  .event-grid {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 70vh 70vh 70vh;
  }
  /* .heading {
    margin-bottom: 10vh;
  } */
}

