.about-us-text {
    width: 80%;
    display: flex;
    flex-direction: column;
    margin: auto;
    /* margin-top: 0%; */
    z-index: 2;
}

.about-us-text p{
    line-height: 2em;
}

.main-heading {
    font-size: 10vh;
    margin-bottom: 0;
    text-align: center;
}

.sub-heading-about-us {
    text-align: center;
    margin-top: 0;
    font-size: 3vh;
    margin-bottom: 0;
}

.main-desc, .desc-link {
    margin-top: 0;
    display: inline;
    font-size: 2.4vh;
}

.desc-link {
    color: #000;
}

.desc-link:hover {
    filter: invert(48%) sepia(6%) saturate(6%) hue-rotate(329deg) brightness(102%) contrast(89%);
}

@media screen and (max-aspect-ratio: 6/5)
{
    .about-us-content {
        /* margin: auto 0 auto 0; */
        padding: 5vh 0 5vh 0;
        width: 100%;

        display: -webkit-flex;
        display: flex;

        -webkit-flex-direction: column-reverse;
        flex-direction: column-reverse;
        justify-content: center;
        flex-wrap: wrap;
    }
    .about-us-text {
        text-align: center;
        width: 70%;
    }
    
    .about-us-text ul {
        padding: 0;
    }

    .main-heading {
        font-size: 10vh;
        justify-content: center;
        display: flex;
    }
}
