.faq-block {
  /* border-bottom: 1px solid #fff; */
  width: 100%;
}

.faq-answer {
  padding: 10pt;
  font-style: italic;
}

.faq-content {
  /* width: 50vw; */
  min-height: 6rem;
  margin: auto 20vw auto 20vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.faq-content :is(h1, h2, h3, h4) {
  text-align: center;
  margin: auto 0 auto 0;
}

.faq-heading {
  text-align: center;
  /* border-bottom: solid 2px white; */
}

@media screen and (max-aspect-ratio: 6/5) {  
  .faq-content {
    margin-top: 10vh;
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;
    -webkit-flex-direction: column-reverse;
    flex-direction: column-reverse; 
    /* height: 60vh; */
   }
   .faqStack{
    flex-wrap: wrap;
   }
  .faq-section {
    text-align: center;
  }
  .faq-container {
    margin-top: 1vh;
  }
}