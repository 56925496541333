.overview-block {
    padding: 10vh 10vw 10vh 10vw;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.overview-content {
    width: 80vw;
    display: flex;
    flex-direction: column;
    row-gap: 5vh;
    margin: auto;

    max-height: 100%;
    justify-content: center;
    line-height: 2em;
}

.overview-img-container {
    background-color: white;
    width: 40%;
    height: 100%;
    overflow: hidden;
    margin: auto;
}

.overview-img {
    width: 130vh;
    height: 130vh;
    object-fit: cover;
    margin: auto;
    margin-left: 0;
    margin-top: -20vh;

    border-radius: 50%;
    z-index: 5;
}

.overview-text {
    width: 80%;
    display: flex;
    flex-direction: column;
    margin: auto;
    z-index: 2;
}

.main-heading {
    font-size: 10vh;
    margin-bottom: 0;
}

.sub-heading {
    margin-top: 0;
    font-size: 3vh;
    margin-bottom: 0;
}

.main-desc, .desc-link {
    margin-top: 0;
    display: inline;
    font-size: 2.4vh;
}

.desc-link {
    color: #000;
}

.desc-link:hover {
    filter: invert(48%) sepia(6%) saturate(6%) hue-rotate(329deg) brightness(102%) contrast(89%);
}

.gradient-border, .gradient-border-padded {
    background: -moz-linear-gradient(center bottom, #8b2fc4 0%, #a600de 34%, #f55fff 100%);
    background: -webkit-gradient(linear, left bottom, right top, color-stop(0, #7300ba), color-stop(0.33, #a600de), color-stop(1, #f55fff));

    background-origin: border-box;
    -moz-background-origin: border;

    border: 8px solid transparent;
    border-radius: 35px;
    box-shadow: inset 1000vw 0 0 #000;
    
    border-radius: 35px;
}

.gradient-border-padded {
    padding: 2rem;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

@media screen and (max-aspect-ratio: 6/5)
{
    .overview-img {
        margin-left: -80vw;
        margin-top: -10vh;
        width: 200vw;
        height: 200vw;
    }

    .overview-content {
        width: 100%;
        line-height: 2.0em;
    }

    .overview-text {
        width: 90%;
    }

    .main-heading {
        font-size: 8vh;
    }
}