.landing-content {
    z-index: 2;
    position: relative;
}

.landing-content, .landing-overlay, .landing-underlay {
    width: 80vw;
    display: flex;
    margin: auto;
    max-height: 100%;
}

.landing-overlay {
    position: relative;
    z-index: 10;
}

.landing-underlay {
    position: relative;
    margin-left: -100%;
    z-index: 5;
}

.landing-background {
    display: flex;
    align-items: left;
    margin-top: -30vh;
    margin-left: -130vh;
    background-size: 5px;
    background-repeat: repeat;
}

.landing-background-img {
    min-width:1500px;
    rotate: -30deg;
    width: 120vh;
    mask-image: url(./floaters/line.svg);
    mask-repeat: repeat;
    mask-size: 5px 100px;
}

.landing-img-container {
    width: 40%;
    margin: auto;
    margin-right: 0;
}

.landing-img {
    height: 100vh;
    margin-right: 5vw;
    margin-top: -11vh;
}

.landing-text {
    width: 60%;
    display: flex;
    flex-direction: column;
    margin: auto;
    z-index: 2;
}

.landing-logo-container {
    display: flex;
    flex-direction: row;
    justify-content: left;
}

.main-heading {
    font-size: 10vh;
    margin-bottom: 0;
}

.sub-heading {
    margin-top: 0;
    font-size: 3vh;
    margin-bottom: 0;
}

.main-desc, .desc-link {
    margin-top: 0;
    display: inline;
    font-size: 2.4vh;
}

.desc-link {
    color: #000;
}

.desc-link:hover {
    filter: invert(48%) sepia(6%) saturate(6%) hue-rotate(329deg) brightness(102%) contrast(89%);
}

@keyframes float {
  0% { transform: translate(0, 0); }
  50% { transform: translate(100px, 100px) }
  100% { transform: translate(0, 0); }
}

@media screen and (max-aspect-ratio: 6/5)
{
    .landing-content {
        height: 100vh;
    }

    .landing-text {
        margin-top: 30vh;
    }
    .landing-img {
        object-fit: cover;
        margin-left: -30vw;
        margin-top: 0vh;
        height: 75vh;
    }
}