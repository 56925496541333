body, input[type="text"] {
  color: rgb(238, 238, 238);
  margin: 0;
  font-family: 'Roboto Mono', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a{
    color: darkorchid;
}

a:hover{
  color: fuchsia;
}

code {
  font-family: 'Roboto Mono', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

path {
  fill: linear-gradient(34deg, rgba(115, 0, 186, 1) 0%, rgba(245, 95, 255, 1) 100%);
}

.h1 .h2 .h3 .h4 .p {
  color: white;
}

.heading {
  font-size: 40pt;
  color: white;
  margin-top: 0;
}


/* roboto-regular*/
/* @font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'),
       url('./fonts/roboto.woff') format('woff'),
       url('./fonts/roboto.woff2') format('woff2'); 
} */
