.stack {
  width: 80%;
  margin: auto;
  text-align: center;
  justify-content: center;
}

.main-heading{
  margin-bottom: 0.5em;
}

.schedule-table {
  width: 100%;
  margin: auto;
  background: linear-gradient(135deg, #8b2fc4 0%, #a600de 50%, #f55fff 100%);
  border-radius: 2em;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2em;
}

.schedule-table h2 {
  text-align: center;
  color: #fff;
  padding: 0.5em;
  border-bottom: 2px solid #fff;
  margin-bottom: 0.8em;
  font: 1000;
}

.day-schedule {
  width: 6em;
  position: relative;
  margin: 0 auto;
  padding: 0 3em;
  flex: 1;
}

.day-schedule:not(:last-child)::after {
  content: '';
  position: absolute;
  top: 1em;
  right: 0;
  bottom: 1em;
  width: 0.5em;
  border-radius: 5000px;
  background-color: #d4d4d4;
}

.event {
  color: #fff;
  text-align: end;
}

.time {
  color: #fff;
  text-align: start;
  display: block;
  font-weight: 600;
  padding-top: 1em;
  text-decoration: underline;
}

.schedule-table time:not(:first-child) {
  border-top: 1px solid #b1b1b1; 
}

.registration-text {
  width: 100%;
  text-align: start;
  justify-content: left;
}

.stack h3 {
  text-align: start;
}

@media (max-width: 768px) {
  .stack {
    width: 100%;
  }

  .registration-text{
    width:95%;
    padding: 1em;
  }

  #schedule-block{
    width:100%;
    padding: 0 !important;
  }

  .schedule-table {
    flex-direction: column;
    width: 95%;
    padding: 0.5em;
  }

  .day-schedule {
    width: auto;
    margin: auto;
    padding: 0.5em 0;
  }

  .day-schedule:not(:last-child)::after {
    width: 100%;
    height: 0.5em;
    right: auto;
    top: auto;
    left: 0;
    bottom: -0.5em;
  }
}

